
const WorkTab = [
    // [
              {
                  id: 1,
                  category:"banquitehall",
                  imageUr1: "https://content.jdmagicbox.com/comp/mumbai/65/022pg026565/catalogue/ajivasan-banquet-hall-santacruz-west-mumbai-banquet-halls-evq2asntwv.jpg",
                
                  
                },
              {
                  id: 2,
                  category:"banquitehall",
                  imageUr1:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8U54nCwZ6I2uoO8Psyr7OQtXDaNS2xPew9Q&s",
                },
              {
                  id: 3,
                  category:"banquitehall",
                  imageUr1: "https://media.weddingz.in/images/9c83ab5c89c7cfaa8dc3bcbfd5a3b809/the-10-most-popular-banquet-halls-in-mumbai-3.jpg"    ,       
             
                },
              {
                  id: 4,
                  category:"banquitehall",
                  imageUr1:"https://images.venuebookingz.com/20909-1591964694-wm-hotel-mina-mumbai-3.jpg" ,         
                  
                  
                },
             
                // ******************************************banquitehall************************************************************
      
               
      
                {
                  id:1,
                  
                  category:"weddinglawn",
                  imageUr1: "https://www.eternalweddingz.in/storage/venue_images/35WfGZlI4wpb4kad2VTMKvz8gIW0kSmGgU7CdKkd.webp",
                 
                },
                {
                  id:2,
                  
                  category:"banquitehall",
                  imageUr1:"https://cdn0.weddingwire.in/vendor/0799/3_2/960/jpg/wedding-venue-divyata-inn-marriage-lawn-lawn-space-1_15_230799-163414229474681.jpeg",
                
                },
      
                {
                  id:3,
                  
                  category:"banquitehall",
                  imageUr1:"https://media.weddingz.in/images/77ef4b7d93cb6c8e61a9e1e5ee766f78/wedding-lawns-in-dwarka-delhi-to-host-the-most-special-day-of-your-life.jpg",
                  
                },
                {
                  id:4,
                  
                  category:"banquitehall",
                  imageUr1:"https://cdn0.weddingwire.in/vendor/0799/3_2/960/jpg/wedding-venue-divyata-inn-marriage-lawn-lawn-space-1_15_230799-163414229474681.jpeg",
                  
                  
                },
      
                // **************************************************CATERINGSERVICES*******************************************************
                {
                  id:1,
                  category:"CATERINGSERVICES",
                  imageUr1:"https://5.imimg.com/data5/SELLER/Default/2024/2/391188194/JI/GX/KD/212879760/hotel-catering-service.jpeg",
                  
                  
                },
                {
                  id:2,
                  category:"CATERINGSERVICES",
                  imageUr1:"https://5.imimg.com/data5/SELLER/Default/2022/8/EB/MU/XM/30531514/catering-services.jpeg",
                 
                
                },
                {
                  id:3,
                  category:"CATERINGSERVICES",
                  imageUr1:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQevwNzu3pxCE-JwAvipt2PkioUR_kyPPl2Sg&s",
                },
                {
                  id:4,
                  category:"CATERINGSERVICES",
                  imageUr1:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQxjYUlrEpbx4uwlEDhVIagZqftzQBALsqd-A&s",
                },
              
      
                // ***************************************************weddingplanner Kitchen*************************************************************************
                {
                  id:1,
                  category:"weddingplanner",
                  imageUr1:"https://image.wedmegood.com/resized/540X/uploads/project/202177/1657025324_PLD08007.jpg?crop=8,222,2031,1142",
                },
          
                {
                  id:2,
                  category:"weddingplanner",
                  imageUr1:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg4z4j9dD1mXaxaribOMJKg7Xt75iy9Q8cmg&s",
                  
                },
          
                {
                  id:3,
                  category:"weddingplanner",
                  imageUr1:"https://image.wedmegood.com/resized/540X/uploads/member/4317485/1685104089_WhatsApp_Image_2023_05_26_at_5.40.43_PM.jpeg?crop=6,116,1012,569",
                  
                },
                {
                  id:4,
                  category:"weddingplanner",
                  imageUr1:"https://d28w5yx6jp7qan.cloudfront.net/TheWeddingwale-_DSC2378-906507369.jpg",
                  
                  
                },
                // ***************************************************eventmagagment*************************************************************************
                {
                  id:1,
                  category:"eventmagagment",
                  imageUr1:"https://lh5.googleusercontent.com/proxy/U47y8_8HB4T3IGrTq8aLyb7mvbHtMoRG5fgKPx1OnadMmRBGrSUbtLhKQXUE3RondLGoaq13Zn5cFIjWXyDaykiaJe_QbDFpfbEE2hz_itTaOS7alvigY8-WZA",
                },
          
                {
                  id:2,
                 
                  category:"eventmagagment",
                  imageUr1:"https://cdn0.weddingwire.in/vendor/6052/3_2/960/jpg/31890647-931314397030567-4011836334518829056-n_15_126052.jpeg",
                  
                },
          
                {
                  id:3,
                 
                  category:"eventmagagment",
                  imageUr1:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ9JlSru_ghLlCi2MOZIzGkVEf4nKxEt5kfYw&s",
                  
                },

                {
                  id:4,
                  category:"eventmagagment",
                  imageUr1:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSeOa6ztgA94n3i3n0W5p5rep2wYwg2yjKXszUJ0auh_FASwdbcqov3JD4BmdKqkCW71pY&usqp=CAU",
                  
                  
                }
          
      // ]
]

export default WorkTab