import React from "react";
import { BrowserRouter, Route, Routes, Switch } from "react-router-dom";
import "./App.css";
import Home from "./Components/Home/Home";
import Contact from "./Pages/Contact";
import Gallery from "./Pages/Gallery";
import Navibar from "./Components/Navibar/Navibar";
import About from "./Pages/About";
import Banquet from "./Pages/Banquet";
import Event from "./Pages/Event";
import GalleryData from "./Pages/GalleryData";

function App() {
  return (
    <>
      <Navibar />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-page" element={<About />} />
        {/* <Route path="/banquet-page" element={<Banquet />} /> */}
        <Route path="/gallery-page" element={<Gallery />} />
        {/* <Route path="/event-page" element={<Event />} /> */}
        <Route path="/contact" element={<Contact />} />
        {GalleryData.map((v, i) => (
          <Route key={i} exact path={v.slug} element={<Event />} />
        ))}
      </Routes>
    </>
  );
}

export default App;
